"use strict";

  import angular from 'angular';

// // staging
// export const API_V2_BASE_URL = 'https://staging.adminv2.talentlitmus.com';
// export const API_V2_KEY = 'SSHBHunw5mk9uJ8GrSWKSj9SqNFxSuP9';

// production
export const API_V2_BASE_URL = 'https://api.adminv2.talentlitmus.com';
export const API_V2_KEY = '3hppQ6ehSJZ5qpcBP7G3LX2akkHBSFas';

 export const API_V2_KEY2 = 'MYLcvDAg5175KH5vTvGtvanxLtFNr8yU';

export default angular.module('leafApp.constants', [])
  .constant('appConfig', require('../../server/config/environment/shared'))
  .name;

